<template>
    <b-card title="Edit System Promo">
        <b-col cols="12">
            <label>Name</label>
            <b-form-input placeholder="Project Name" v-model="systemPromo.name" disabled></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Compressor (Unit) >=</label>
            <b-form-input placeholder="Extend Month" v-model="systemPromo.compressor"></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Fan Coil (Unit) >=</label>
            <b-form-input placeholder="Extend Month" v-model="systemPromo.fanCoil"></b-form-input>
        </b-col>

        <b-col cols="12" class="mt-2">
            <b-button variant="success" @click="doUpdateSystemPromo">Update</b-button>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            systemPromo: {},
        }
    },
    mounted() {
        this.getSystemPromo().then((data) => {
            // // BAD !!!
            // let id = this.$route.params.id;
            // let warranty = data.filter((w) => {
            //     if (w._id === id) {
            //         return true;
            //     }

            //     return false;
            // })

            this.systemPromo = data[0];
        })
    },
    methods: {
        ...mapActions({
            getSystemPromo: 'systemPromo/getSystemPromo',
            updateSystemPromo: 'systemPromo/updateSystemPromo'
        }),
        doUpdateSystemPromo() {
            this.updateSystemPromo(this.systemPromo).then(async () => {
                this.$bvToast.toast("Successfully Updated System Promo", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                await sleep(1000);
                this.$router.go(-1);
            }).catch((e) => {
                console.error(e)
                this.$bvToast.toast(`Update System Promo Failed`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            })
        }
    }
}
</script>